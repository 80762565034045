
import {
  defineComponent,
  PropType,
  computed,
  ComputedRef,
} from 'vue';

import loadingImg from '@/assets/loading.png';

export default defineComponent({
  name: 'item',
  props: {
    name: {
      type: String,
      required: true,
    },
    to: {
      type: Object as PropType<{ name: string, params: { [key:string]: string } }>,
    },
    imageId: {
      type: String,
      required: true,
    },
    isRead: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const imgSrc: ComputedRef<string> = computed(() => `/api/pages/${props.imageId}/minified`);

    return {
      loadingImg,
      imgSrc,
    };
  },
});
