import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c3cb424"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = ["data-src", "src"]
const _hoisted_5 = { class: "text-2xl font-bold text-gray-800 dark:text-gray-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, { to: _ctx.to }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col py-2 px-4 bg-white dark:bg-gray-900 rounded mb-4 shadow-sm", _ctx.isRead ? 'opacity-50' : ''])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("img", {
                "data-src": _ctx.imgSrc,
                src: _ctx.loadingImg,
                alt: "cover image",
                class: "mr-8 image"
              }, null, 8, _hoisted_4),
              _createElementVNode("div", null, [
                _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.name), 1),
                _renderSlot(_ctx.$slots, "legend", {}, undefined, true)
              ])
            ])
          ])
        ])
      ], 2)
    ]),
    _: 3
  }, 8, ["to"]))
}